import React, { Component } from 'react'
import { Menu, Dropdown } from 'antd'
import { MdTranslate } from 'react-icons/md'
import ToolbarCalendarMenu from './ToolbarCalendarMenu'

class Toolbar extends Component {
  langMenu = () => {
    const { language, onLanguageChange } = this.props
    return (
      <Menu onClick={onLanguageChange} selectedKeys={[language]}>
        <Menu.Item key="en-US">English</Menu.Item>
        <Menu.Item key="zh-CN">简体中文</Menu.Item>
        <Menu.Item key="zh-TW">繁體中文</Menu.Item>
      </Menu>
    )
  }

  render() {
    const { language, calendar1, calendar2, onCalendarChange } = this.props
    return (
      <div className="toolbar init">
        <Dropdown overlay={this.langMenu()} placement="topRight">
          <MdTranslate
            size={24}
            className="toolbar-icon"
            color={'var(--dark)'}
          />
        </Dropdown>
        <ToolbarCalendarMenu
          language={language}
          onMenuClick={onCalendarChange}
          selectedKeys={calendar2 ? [calendar1, calendar2] : [calendar1]}
        />
      </div>
    )
  }
}

export default Toolbar
