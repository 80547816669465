import moment from 'moment'
import { i18n } from './i18n'

export const chineseMonthNumbers = info =>
  info.LeapMonth === 0
    ? [...Array(12).keys()].map(m => m + 1)
    : [...Array(13).keys()].map((m, i) =>
        m >= info.LeapMonth && i > 0 ? m : m + 1
      )

export const chineseMonthNames = (monthDays, leapMonth, lang, calendar) => {
  let months = i18n.months
  if (calendar === 'korean') months = i18n.monthsKorean
  else if (calendar === 'japanese') months = i18n.monthsJapanese
  else if (calendar === 'tibetan') months = i18n.monthsTibetan

  let monthsShort = i18n.monthsShort
  if (['tibetan', 'mongolian'].includes(calendar))
    monthsShort = i18n.monthsShort2

  let names = monthDays.map((m, i) => {
    if (leapMonth === 0) {
      return [monthsShort[lang][i], months[lang][i]]
    } else {
      if (i <= leapMonth - 1) {
        return [monthsShort[lang][i], months[lang][i]]
      } else if (i === leapMonth) {
        return [
          i18n.leap[lang] + monthsShort[lang][i - 1],
          i18n.leap[lang] + months[lang][i - 1]
        ]
      } else {
        return [monthsShort[lang][i - 1], months[lang][i - 1]]
      }
    }
  })
  if (
    lang.startsWith('zh') &&
    ['chinese', 'korean', 'vietnamese', 'japanese'].includes(calendar)
  )
    names = names.map((n, i) => [
      n[0] + (monthDays[i] === 30 ? '大' : '小'),
      n[1]
    ])
  return names
}

const combineGanzhi = (ganIdx, zhiIdx, lang, calendar) => {
  if (lang !== 'en-US')
    return `${i18n.gan[lang][ganIdx]}${i18n.zhi[lang][zhiIdx]}`
  else {
    let ganzhi = i18n.gan[lang][calendar]
      ? `${i18n.gan[lang][calendar][ganIdx]} ${
          i18n.zhi[lang][calendar][zhiIdx]
        }`
      : `${i18n.gan[lang].chinese[ganIdx]} ${i18n.zhi[lang].chinese[zhiIdx]}`
    if (calendar !== 'vietnamese') {
      ganzhi = ganzhi.replace(/\s/, '').toLowerCase()
      ganzhi = ganzhi.charAt(0).toUpperCase() + ganzhi.slice(1)
    }
    return ganzhi
  }
}

export const year2GanIdx = year => ((year % 10) + 6) % 10
export const year2ZhiIdx = year => ((year % 12) + 8) % 12
const month2GanIdx = (year, lunarMonth, lang) =>
  ((year2GanIdx(year) + 1) * 2 + lunarMonth - 1) % 10
const month2ZhiIdx = (lunarMonth, lang) => (lunarMonth + 1) % 12

export const year2Ganzhi = (year, lang, calendar) =>
  combineGanzhi(
    year2GanIdx(year, lang),
    year2ZhiIdx(year, lang),
    lang,
    calendar
  )

export const month2Ganzhi = (year, month, lang, calendar) =>
  combineGanzhi(
    month2GanIdx(year, month, lang),
    month2ZhiIdx(month, lang),
    lang,
    calendar
  )

const date2GanIdx = date => {
  let year = moment(date).year()
  let month = moment(date).month() + 1
  if (month === 1 || month === 2) {
    year -= 1
    month += 12
  }
  const day = moment(date).date()
  const c = Math.floor(year / 100)
  const y = year % 100
  return (
    (4 * c +
      Math.floor(c / 4) +
      5 * y +
      Math.floor(y / 4) +
      Math.floor((3 * (month + 1)) / 5) +
      day -
      3 -
      1) %
    10
  )
}

export const date2ZhiIdx = date => {
  let year = moment(date).year()
  let month = moment(date).month() + 1
  if (month === 1 || month === 2) {
    year -= 1
    month += 12
  }
  const day = moment(date).date()
  const c = Math.floor(year / 100)
  const y = year % 100
  return (
    (8 * c +
      Math.floor(c / 4) +
      5 * y +
      Math.floor(y / 4) +
      Math.floor((3 * (month + 1)) / 5) +
      day +
      7 +
      (month % 2 === 0 ? 6 : 0) -
      1) %
    12
  )
}

export const date2Ganzhi = (date, lang, calendar) => {
  const ganIdx = date2GanIdx(date)
  const zhiIdx = date2ZhiIdx(date)

  return combineGanzhi(ganIdx, zhiIdx, lang, calendar)
}

export const zodiac = (year, lang, calendar) => {
  if (calendar === 'vietnamese')
    return i18n.vietnameseZodiacNames[lang][year2ZhiIdx(year)]
  else if (calendar === 'japanese')
    return i18n.japaneseZodiacNames[lang][year2ZhiIdx(year)]
  else return i18n.chineseZodiacNames[lang][year2ZhiIdx(year)]
}
