import moment from 'moment'
import { fetchYear } from './api'
import { i18n } from './i18n'
import { festivals } from './festivals'
import { ordinal_suffix_of } from './utils'
import * as chineseCal from './chineseCal'
import * as daiCal from './daiCal'

const long_list = {
  chinese: 120,
  korean: 135,
  vietnamese: 105,
  japanese: 135,
  tibetan: 120,
  mongolian: 120,
  persian1: 52.5,
  persian2: 52.5,
  dai1: 120,
  dai2: 120
}

// convert Gregorian date to JD if gregorian is true,
// otherwise covert Julian date to JD
export const gregorian2JD = (date, gregorian = true) => {
  const year = moment(date).year()
  const month = moment(date).month() + 1
  const day = moment(date).date()

  const a = Math.floor((14 - month) / 12)
  const y = year + 4800 - a
  const m = month + 12 * a - 3
  let jd = day + Math.floor((153 * m + 2) / 5) + 365 * y + Math.floor(y / 4)
  if (gregorian) jd += -Math.floor(y / 100) + Math.floor(y / 400) - 32045
  else jd -= 32083

  return jd
}

// convert JD to Gregorian date if gregorian is true,
// otherwise covert to Julian date
export const JD2gregorian = (t, calendar, gregorian = true) => {
  const jd = calendar ? t + (long_list[calendar] * 0.5) / 180 : t
  const jdn = jd % 1 < 0.5 ? Math.floor(jd) : Math.ceil(jd)
  let f = jdn + 1401
  if (gregorian)
    f += Math.floor((Math.floor((4 * jdn + 274277) / 146097) * 3) / 4) - 38
  const e = 4 * f + 3
  const g = Math.floor((e % 1461) / 4)
  const h = 5 * g + 2
  const day = Math.floor((h % 153) / 5) + 1
  const month = ((Math.floor(h / 153) + 2) % 12) + 1
  const year = Math.floor(e / 1461) - 4716 + Math.floor((14 - month) / 12)

  return moment([year, month - 1, day])
}

export const gregorian2julian = date =>
  JD2gregorian(gregorian2JD(date), null, false)

export const julian2gregorian = date => JD2gregorian(gregorian2JD(date, false))

const traditionalYear = (year, calendar) => {
  // Yellow Emperor
  if (calendar === 'chinese') return year + 2698
  // Dangun
  else if (calendar === 'korean') return year + 2333
  // Tibetan royal year
  else if (calendar === 'tibetan') return year + 127
  // Solar Hijri
  else if (calendar.startsWith('persian')) return year - 621
  // Dai Celandar
  else if (calendar.startsWith('dai')) return year - 638
  else return year
}

const getCalendarPromises = (date, calendar2) => {
  const year = moment(date).year()

  const info = Array(3).fill(null)

  const promises = [year - 1, year, year + 1].map((y, i) =>
    fetchYear(y, calendar2).then(res => {
      if (res != null && res.length > 0) info[i] = res[0]
    })
  )

  return { year, info, promises }
}

const getFirstDaysOfMonthsIdx = monthDays =>
  [0, ...monthDays.reduce((s, x, i) => [...s, x + (s[i - 1] || 0)], [])].slice(
    0,
    -1
  )

const getYearOrMonthIdx = (firstDays, currDay) => {
  let idx = firstDays.findIndex(d => moment(d).isAfter(currDay))
  if (idx === -1) idx = firstDays.length
  return idx
}

const monthInfos = (info, suffix = '') => {
  let months = Array(12)
    .fill(0)
    .map((_, i) => info[`M${i + 1}${suffix}`])
  if (info.LeapMonth !== 0) months = [...months, info[`M13${suffix}`]]

  return months
}

export const getChineseCalendar = (date, lang, calendar2) => {
  const { year, info, promises } = getCalendarPromises(date, calendar2)

  return Promise.all(promises).then(res => {
    if (info.some(i => i == null)) return null

    const firstDaysOfYears = info.map(i => JD2gregorian(i.FirstDay, calendar2))
    const monthDays = info.map(i => monthInfos(i).map(m => (m === 1 ? 30 : 29)))
    const firstDaysOfMonthsIdx = monthDays.map(md =>
      getFirstDaysOfMonthsIdx(md)
    )

    const monthNames = monthDays.map((md, i) =>
      chineseCal.chineseMonthNames(md, info[i].LeapMonth, lang, calendar2)
    )
    const monthNumbers = info.map(i => chineseCal.chineseMonthNumbers(i))

    // 24 solar terms of current and next year
    const jieqis = info.map(i =>
      Array(24)
        .fill(0)
        .map((_, idx) => i[`J${idx + 1}`] + i.FirstDay)
        .map(jq => JD2gregorian(jq, calendar2))
    )

    const dates = Array(42)
      .fill(0)
      .map((_, i) => {
        const currDay = moment(date).add(i, 'd')

        const yearIdx = getYearOrMonthIdx(firstDaysOfYears, currDay)
        const yearIdxForJieqi = getYearOrMonthIdx(
          jieqis.map(j => j[0]),
          currDay
        )

        const firstDayOfYear = firstDaysOfYears[yearIdx - 1]
        const firstDaysOfMonths = firstDaysOfMonthsIdx[yearIdx - 1].map(x =>
          moment(firstDayOfYear).add(x, 'd')
        )

        const idx = getYearOrMonthIdx(firstDaysOfMonths, currDay)

        const [month, monthFull] = monthNames[yearIdx - 1][idx - 1]
        const dayIdx = moment(currDay).diff(firstDaysOfMonths[idx - 1], 'd')
        const day = i18n.days[lang][dayIdx]

        // Ganzhi
        const year2 = year + yearIdx - 2
        const monthNumber = monthNumbers[yearIdx - 1][idx - 1]
        const yearGanzhi = chineseCal.year2Ganzhi(year2, lang, calendar2)
        const monthGanzhi = chineseCal.month2Ganzhi(
          year2,
          monthNumber,
          lang,
          calendar2
        )
        const dateGanzhi = chineseCal.date2Ganzhi(currDay, lang, calendar2)

        const fullDate = lang.startsWith('zh')
          ? `${yearGanzhi}年${monthFull}${day}`
          : `${monthFull} ${day}, Year ${yearGanzhi}`

        const ganzhiDate = lang.startsWith('zh')
          ? `${yearGanzhi}年${monthGanzhi}月${dateGanzhi}日`
          : `Day ${dateGanzhi}, Month ${monthGanzhi}, Year ${yearGanzhi}`

        // Jieqi
        const jieqiIdx = jieqis[yearIdxForJieqi - 1].findIndex(jq =>
          moment(jq).isSame(currDay, 'd')
        )
        const jieqi =
          jieqiIdx === -1
            ? null
            : lang !== 'en-US'
            ? i18n.jieqiNames[lang][(jieqiIdx + 21) % 24]
            : i18n.jieqiNames[lang][calendar2][(jieqiIdx + 21) % 24]

        // festival
        let festival =
          festivals[calendar2] &&
          festivals[calendar2][`M${monthNumber}D${dayIdx + 1}`]
            ? festivals[calendar2][`M${monthNumber}D${dayIdx + 1}`][lang]
            : null

        // no festivals in leap months
        if (info[yearIdx - 1].LeapMonth === monthNumber && idx !== monthNumber)
          festival = null

        // special festivals
        if (festival == null) {
          if (calendar2 === 'chinese') {
            // Hanshi Festival
            festival = moment(jieqis[yearIdxForJieqi - 1][7]).isSame(
              moment(currDay).add(1, 'd'),
              'd'
            )
              ? festivals[calendar2].Hanshi[lang]
              : festival
            // New Year's Eve
            festival =
              monthNumber === 12 &&
              dayIdx + 1 === monthDays[yearIdx - 1][idx - 1]
                ? festivals[calendar2].Chuxi[lang]
                : festival
          } else if (calendar2 === 'korean') {
            // Hansik
            festival =
              moment(currDay).diff(jieqis[yearIdxForJieqi - 1][0], 'd') === 105
                ? festivals[calendar2].Hansik[lang]
                : festival
            // New Year's Eve
            festival =
              monthNumber === 12 &&
              dayIdx + 1 === monthDays[yearIdx - 1][idx - 1]
                ? festivals[calendar2].SeotdalGeumeum[lang]
                : festival
          } else if (calendar2 === 'japanese') {
            // New Year's Eve
            festival =
              monthNumber === 12 &&
              dayIdx + 1 === monthDays[yearIdx - 1][idx - 1]
                ? festivals[calendar2].Omisoka[lang]
                : festival

            // Setsubun
            festival =
              moment(currDay).diff(jieqis[yearIdxForJieqi - 1][3], 'd') === -1
                ? festivals[calendar2].Setsubun[lang]
                : festival

            // Hachiju Hachiya
            festival =
              moment(currDay).diff(jieqis[yearIdxForJieqi - 1][3], 'd') === 87
                ? festivals[calendar2].HachijuHachiya[lang]
                : festival

            // Nihyaku tōka
            festival =
              moment(currDay).diff(jieqis[yearIdxForJieqi - 1][3], 'd') === 209
                ? festivals[calendar2].NihyakuToka[lang]
                : festival

            // Nihyaku hatsuka
            festival =
              moment(currDay).diff(jieqis[yearIdxForJieqi - 1][3], 'd') === 219
                ? festivals[calendar2].NihyakuHatsuka[lang]
                : festival

            // Hatsu Uma
            festival =
              monthNumber === 2 &&
              chineseCal.date2ZhiIdx(currDay) === 6 &&
              dayIdx + 1 <= 12
                ? festivals[calendar2].HatsuUma[lang]
                : festival
          }
        }

        // Nines
        let nines = null
        if (calendar2 === 'chinese') {
          const nines1 =
            moment(currDay).diff(jieqis[yearIdxForJieqi - 1][0], 'd') + 1
          const nines2 =
            moment(currDay).diff(jieqis[yearIdxForJieqi - 1][24], 'd') + 1
          const ninesNum =
            nines1 >= 1 && nines1 <= 81
              ? nines1
              : nines2 >= 1 && nines2 <= 81
              ? nines2
              : null
          let ninesCount = ninesNum % 9
          if (ninesCount === 0) ninesCount = 9
          if (ninesNum)
            nines = i18n.ninesNames[lang][
              parseInt((ninesNum - 1) / 9, 10)
            ].replace(/0/, ninesCount)
        }

        // Rokuyou (Liuyao)
        const rokuyou =
          i18n.rokuyouNames[lang][
            (monthNumbers[yearIdx - 1][idx - 1] + dayIdx + 1) % 6
          ]

        return {
          month,
          day,
          short: day === i18n.days[lang][0] ? month : day,
          main: day === i18n.days[lang][0] ? true : false,
          highlight: jieqi != null || festival != null,
          fullDate,
          festival,
          ganzhiDate,
          zodiac: chineseCal.zodiac(year2, lang, calendar2),
          traditionalYear: traditionalYear(year2, calendar2),
          jieqi,
          nines,
          rokuyou
        }
      })
    return dates
  })
}

export const getTibetanCalendar = (date, lang, calendar2) => {
  let { year, info, promises } = getCalendarPromises(date, calendar2)

  return Promise.all(promises).then(res => {
    if (info.some(i => i == null)) return null

    info = info.map(currInfo => {
      Array(13)
        .fill(0)
        .forEach((_, i) => {
          let numDays = 30
          numDays -= currInfo[`M${i + 1}S1`] !== 0 ? 1 : 0
          numDays -= currInfo[`M${i + 1}S2`] !== 0 ? 1 : 0
          numDays += currInfo[`M${i + 1}R1`] !== 0 ? 1 : 0
          numDays += currInfo[`M${i + 1}R2`] !== 0 ? 1 : 0
          currInfo[`M${i + 1}`] = numDays === 30 ? 1 : 0
        })

      return currInfo
    })

    const firstDaysOfYears = info.map(i => JD2gregorian(i.FirstDay, calendar2))
    const monthDays = info.map(i => monthInfos(i).map(m => (m === 1 ? 30 : 29)))
    const firstDaysOfMonthsIdx = monthDays.map(md =>
      getFirstDaysOfMonthsIdx(md)
    )
    const monthNames = monthDays.map((md, i) =>
      chineseCal.chineseMonthNames(md, info[i].LeapMonth, lang, calendar2)
    )
    const monthNumbers = info.map(i => chineseCal.chineseMonthNumbers(i))

    const [skipped1, skipped2, repeated1, repeated2] = [
      'S1',
      'S2',
      'R1',
      'R2'
    ].map(suffix => info.map(i => monthInfos(i, suffix)))

    const dates = Array(42)
      .fill(0)
      .map((_, i) => {
        const currDay = moment(date).add(i, 'd')

        const yearIdx = getYearOrMonthIdx(firstDaysOfYears, currDay)

        const firstDayOfYear = firstDaysOfYears[yearIdx - 1]
        const firstDaysOfMonths = firstDaysOfMonthsIdx[yearIdx - 1].map(x =>
          moment(firstDayOfYear).add(x, 'd')
        )

        const idx = getYearOrMonthIdx(firstDaysOfMonths, currDay)

        const [month, monthFull] = monthNames[yearIdx - 1][idx - 1]
        let dayIdx = moment(currDay).diff(firstDaysOfMonths[idx - 1], 'd') + 1

        const isFirstDay = dayIdx === 1
        const special_days = [
          -skipped1[yearIdx - 1][idx - 1],
          -skipped2[yearIdx - 1][idx - 1],
          repeated1[yearIdx - 1][idx - 1],
          repeated2[yearIdx - 1][idx - 1]
        ]
        special_days
          .sort((a, b) => Math.abs(a) - Math.abs(b))
          .filter(Number)
          .forEach(i => {
            dayIdx -= i > 0 && dayIdx > i ? 1 : 0
            dayIdx += i < 0 && dayIdx >= -i ? 1 : 0
          })
        const day = i18n.days[lang][dayIdx - 1]
        const isRepeatedDay =
          dayIdx === repeated1[yearIdx - 1][idx - 1] ||
          dayIdx === repeated2[yearIdx - 1][idx - 1]
        const repeatedDayString = i18n.tibetanRepeatedDay[lang]

        const year2 = year + yearIdx - 2
        const tibetanZodiac = chineseCal.zodiac(year2, lang, calendar2)
        const element =
          i18n.tibetanElements[lang][
            parseInt(chineseCal.year2GanIdx(year2) / 2, 10)
          ]
        const cycleNo = Math.ceil((year2 - 1026) / 60)
        const cycleName =
          calendar2 === 'tibetan' ? i18n.rabjung[lang] : i18n.jaran[lang]

        const fullDate = lang.startsWith('zh')
          ? `第${cycleNo}${cycleName}${element}${tibetanZodiac}年${monthFull}${day}${
              isRepeatedDay ? '（' + repeatedDayString + '）' : ''
            }`
          : `${monthFull} ${day}${
              isRepeatedDay ? ' (' + repeatedDayString + ')' : ''
            }, ${element} ${tibetanZodiac} Year of the ${ordinal_suffix_of(
              cycleNo
            )} ${cycleName}`

        // festival
        const monthNumber = monthNumbers[yearIdx - 1][idx - 1]
        const isSkippedDay =
          dayIdx !== 1 &&
          (dayIdx === skipped1[yearIdx - 1][idx - 1] + 1 ||
            dayIdx === skipped2[yearIdx - 1][idx - 1] + 1)
        let festival =
          festivals[calendar2] &&
          festivals[calendar2][`M${monthNumber}D${dayIdx}`]
            ? festivals[calendar2][`M${monthNumber}D${dayIdx}`][lang]
            : null

        if (festival == null && isSkippedDay)
          festival =
            festivals[calendar2] &&
            festivals[calendar2][`M${monthNumber}D${dayIdx - 1}`]
              ? festivals[calendar2][`M${monthNumber}D${dayIdx - 1}`][lang]
              : null

        // no festivals in leap months
        if (info[yearIdx - 1].LeapMonth === monthNumber && idx !== monthNumber)
          festival = null

        return {
          month,
          day,
          short: isFirstDay ? month : day,
          main: isFirstDay,
          highlight: festival != null,
          fullDate,
          traditionalYear: traditionalYear(year2, calendar2),
          festival
        }
      })
    return dates
  })
}

export const getPersianCalendar = (date, lang, calendar2) => {
  const { year, info, promises } = getCalendarPromises(date, calendar2)

  return Promise.all(promises).then(res => {
    if (info.some(i => i == null)) return null

    const firstDaysOfYears = info.map(i => JD2gregorian(i.FirstDay, calendar2))
    const firstDaysOfMonthsIdx = getFirstDaysOfMonthsIdx([
      31,
      31,
      31,
      31,
      31,
      31,
      30,
      30,
      30,
      30,
      30,
      30
    ])

    const dates = Array(42)
      .fill(0)
      .map((_, i) => {
        const currDay = moment(date).add(i, 'd')
        const yearIdx = getYearOrMonthIdx(firstDaysOfYears, currDay)

        const firstDayOfYear = firstDaysOfYears[yearIdx - 1]
        const firstDaysOfMonths = firstDaysOfMonthsIdx.map(x =>
          moment(firstDayOfYear).add(x, 'd')
        )

        const idx = getYearOrMonthIdx(firstDaysOfMonths, currDay)

        const month = i18n.persianMonths[lang][idx - 1]
        const monthFull = i18n.persianMonthsFull[lang][idx - 1]
        const day = moment(currDay).diff(firstDaysOfMonths[idx - 1], 'd') + 1

        const shYear = traditionalYear(year + yearIdx - 2, calendar2)

        const fullDate =
          lang === 'en-US'
            ? `${day} ${monthFull}, ${shYear} AP`
            : `${shYear}年${monthFull}${day}日`

        let festival = festivals.persian[`M${idx}D${day}`]
          ? festivals.persian[`M${idx}D${day}`][lang]
          : null

        if (festival == null && firstDaysOfYears[yearIdx]) {
          const dayDiff = moment(currDay).diff(firstDaysOfYears[yearIdx], 'd')
          festival =
            dayDiff >= -7 && moment(currDay).isoWeekday() === 3
              ? festivals.persian.ChaharshanbeSuri[lang]
              : festival
        }

        return {
          month,
          day,
          short: day === 1 ? month : day,
          main: day === 1 ? true : false,
          highlight: festival != null,
          fullDate,
          festival
        }
      })

    return dates
  })
}

// see also: http://www.staff.science.uu.nl/~gent0113/islam/islam_tabcal.htm
const JD2islamic = (jd, calendar = 'islamic2') => {
  let leapYears = []

  if (calendar === 'islamic1')
    leapYears = [2, 5, 7, 10, 13, 15, 18, 21, 24, 26, 29]
  else if (calendar === 'islamic2')
    leapYears = [2, 5, 7, 10, 13, 16, 18, 21, 24, 26, 29]
  else if (calendar === 'islamic3')
    leapYears = [2, 5, 8, 10, 13, 16, 19, 21, 24, 27, 29]
  else if (calendar === 'islamic4')
    leapYears = [2, 5, 8, 11, 13, 16, 19, 21, 24, 27, 30]
  const firstDayOfYears = [
    0,
    ...Array(30)
      .fill(0)
      .map((_, i) => 354 + (leapYears.includes(i + 1) ? 1 : 0))
  ]
    .reduce((s, x, i) => [...s, x + (s[i - 1] || 0)], [])
    .slice(0, -1)
    .reverse()

  const n = jd - 1948440
  const iCycle = Math.floor(n / 10631)
  const iYearIdx = firstDayOfYears.findIndex(i => i <= n % 10631)
  const iYear = iCycle * 30 + 30 - iYearIdx
  const n_remain = (n % 10631) - firstDayOfYears[iYearIdx]
  let iMonth = Math.floor(n_remain / 29.5) + 1
  if (iMonth === 13) iMonth = 12 // last day of leap year
  const iDay = n_remain - Math.ceil((iMonth - 1) * 29.5) + 1

  return [iYear, iMonth, iDay]
}

export const getDaiCalendar = (date, lang, calendar2) => {
  const { year, info, promises } = getCalendarPromises(date, calendar2)

  return Promise.all(promises).then(res => {
    if (info.some(i => i == null)) return null

    const firstDaysOfYears = info.map(i => JD2gregorian(i.June1, calendar2))
    const monthDays = info.map(i => daiCal.daiMonthDays(i))
    const firstDaysOfMonthsIdx = monthDays.map(md =>
      getFirstDaysOfMonthsIdx(md)
    )
    const monthNames = monthDays.map(md => daiCal.daiMonthNames(md, lang))
    const monthNumbers = info.map(i => daiCal.daiMonthNumbers(i))

    const dates = Array(42)
      .fill(0)
      .map((_, i) => {
        const currDay = moment(date).add(i, 'd')
        const yearIdx = getYearOrMonthIdx(firstDaysOfYears, currDay)

        const firstDayOfYear = firstDaysOfYears[yearIdx - 1]
        const firstDaysOfMonths = firstDaysOfMonthsIdx[yearIdx - 1].map(x =>
          moment(firstDayOfYear).add(x, 'd')
        )

        const idx = getYearOrMonthIdx(firstDaysOfMonths, currDay)

        const [month, monthFull] = monthNames[yearIdx - 1][idx - 1]

        const day = moment(currDay).diff(firstDaysOfMonths[idx - 1], 'd') + 1
        const dayName = i18n.daiDays[lang][day - 1]

        const year2 = year + yearIdx - 2
        const daiYear = traditionalYear(year2, calendar2)

        const fullDate =
          lang === 'en-US'
            ? `${dayName} of ${monthFull}, ${daiYear}`
            : `${daiYear}年${monthFull}${dayName}`

        const monthNumber = monthNumbers[yearIdx - 1][idx - 1]

        // Ganzhi
        const yearGanzhi = chineseCal.year2Ganzhi(
          year + yearIdx - 2,
          lang,
          calendar2
        )
        const dateGanzhi = chineseCal.date2Ganzhi(currDay, lang, calendar2)

        const ganzhiDate = lang.startsWith('zh')
          ? `${yearGanzhi}年${monthFull}${dateGanzhi}日`
          : `Day ${dateGanzhi}, ${monthFull}, Year ${yearGanzhi}`

        // zodiac
        const zodiac = daiCal.zodiac(
          year + yearIdx - 2,
          monthNumber,
          currDay,
          lang
        )

        // festival
        let festival = festivals.dai[`M${monthNumber}D${day}`]
          ? festivals.dai[`M${monthNumber}D${day}`][lang]
          : null

        // Songkran
        if (monthNumber === 6 || monthNumber === 7) {
          const newYear = JD2gregorian(info[yearIdx - 1].FirstDay, calendar2)
          const newYearEve = JD2gregorian(info[yearIdx - 2].LastDay, calendar2)
          if (moment(currDay).isSame(newYear, 'd')) {
            festival = festivals.dai.NewYear[lang]
          } else if (moment(currDay).isSame(newYearEve, 'd')) {
            festival = festivals.dai.NewYearEve[lang]
          } else if (
            moment(currDay).isAfter(newYearEve, 'd') &&
            moment(currDay).isBefore(newYear, 'd')
          ) {
            festival = festivals.dai.EmptyDay[lang]
          }
        }

        // no festivals in leap months
        if (info[yearIdx - 1].LeapMonth === monthNumber && idx !== monthNumber)
          festival = null

        return {
          month,
          day,
          short: day === 1 ? month : i18n.daiDaysShort[lang][day - 1],
          main: day === 1 ? true : false,
          highlight: festival != null,
          fullDate,
          ganzhiDate,
          zodiac,
          festival
        }
      })

    return dates
  })
}

export const getIslamicCalendar = (date, lang, calendar2) => {
  const dates = Array(42)
    .fill(0)
    .map((_, i) => {
      const currDay = moment(date).add(i, 'd')
      const jd = gregorian2JD(currDay)

      if (jd < 1948440) return { highlight: false, main: '', short: '' }
      let fullDates = []
      let iMonth = 0,
        iDay = 0
      Array(4)
        .fill(0)
        .forEach((_, i) => {
          const cal = `islamic${i + 1}`
          const [y, m, d] = JD2islamic(jd, cal)
          const fullDate =
            lang === 'en-US'
              ? `${d} ${i18n.islamicMonthsFull[lang][m - 1]}, ${y} AH`
              : `${y}年${i18n.islamicMonthsFull[lang][m - 1]}${d}日`
          fullDates = [...fullDates, fullDate]
          if (calendar2 === cal) {
            iMonth = m
            iDay = d
          }
        })

      // festival
      let festival = festivals.islamic[`M${iMonth}D${iDay}`]
        ? festivals.islamic[`M${iMonth}D${iDay}`][lang]
        : null

      return {
        day: iDay,
        short: iDay === 1 ? i18n.islamicMonths[lang][iMonth - 1] : iDay,
        main: iDay === 1 ? true : false,
        highlight: festival != null,
        fullDates,
        festival
      }
    })

  return dates
}
