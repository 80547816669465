import React, { Component } from 'react'
import { DatePicker, LocaleProvider } from 'antd'
import TextLoop from 'react-text-loop'
import moment from 'moment'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import zh_TW from 'antd/lib/locale-provider/zh_TW'
import './App.css'
import AnalogClock from './AnalogClock'
import Calendar from './Calendar'
import Toolbar from './Toolbar'
import Arrows from './Arrows'
import { i18n } from '../utils/i18n'

const { MonthPicker } = DatePicker

class App extends Component {
  state = {
    initialStyle: true,
    currentMonth: null,
    language: 'en-US',
    calendar1: 'gregorian',
    calendar2: null
  }

  onMonthChange = date => {
    this.setState({ currentMonth: date })

    if (this.state.initialStyle) {
      this.removeInitialStyles()
      this.setState({ initialStyle: false })
    }
  }

  onLanguageChange = e => {
    this.setState({ language: e.key })
  }

  onCalendarChange = e => {
    const cal = e.key
    if (['gregorian', 'julian'].includes(cal)) {
      this.setState({ calendar1: cal })
    } else {
      if (this.state.calendar2 === cal) this.setState({ calendar2: null })
      else this.setState({ calendar2: cal })
    }
  }

  antdLocale = () => {
    const language = this.state.language
    if (language === 'zh-CN') return zh_CN
    else if (language === 'zh-TW') return zh_TW
    else return null
  }

  removeInitialStyles = () => {
    const classes = [
      'body',
      '.title',
      '.subtitle',
      '.month-picker',
      '.calendar',
      '.toolbar'
    ]
    classes.forEach(c => {
      let el = document.querySelector(c)
      if (el != null) el.classList.remove('init')
    })
    const classesMulti = ['.calendar-arrow']
    classesMulti.forEach(c => {
      document.querySelectorAll(c).forEach(e => e.classList.remove('init'))
    })
  }

  updateMonth = newMonth => {
    if (this.state.currentMonth != null)
      this.setState({ currentMonth: newMonth })
  }

  prevMonth = () =>
    this.updateMonth(moment(this.state.currentMonth).subtract(1, 'month'))

  nextMonth = () =>
    this.updateMonth(moment(this.state.currentMonth).add(1, 'month'))

  prevYear = () =>
    this.updateMonth(moment(this.state.currentMonth).subtract(1, 'year'))

  nextYear = () =>
    this.updateMonth(moment(this.state.currentMonth).add(1, 'year'))

  render() {
    const { language, calendar1, calendar2, currentMonth } = this.state
    return (
      <div className="App">
        <div className="title noselect init">
          <span>KALENDÆ</span>
          <AnalogClock />
        </div>
        <div className="calendar-wrap">
          <div className="calendar-arrows calendar-arrows-small">
            <Arrows
              direction="left"
              size={20}
              titles={[i18n.prevYear[language], i18n.prevMonth[language]]}
              onClick={[this.prevYear, this.prevMonth]}
            />
            <LocaleProvider locale={this.antdLocale()}>
              <MonthPicker
                className="month-picker noselect init"
                onChange={this.onMonthChange}
                value={currentMonth}
                placeholder={i18n.selectMonth[language]}
                format={i18n.monthFormat[language]}
              />
            </LocaleProvider>
            <Arrows
              direction="right"
              size={20}
              titles={[i18n.nextMonth[language], i18n.nextYear[language]]}
              onClick={[this.nextMonth, this.nextYear]}
            />
          </div>
          <div className="subtitle noselect init">
            <TextLoop
              interval={1500}
              springConfig={{ stiffness: 180, damping: 10 }}
              children={[
                'Gregorian',
                'Julian',
                'Chinese',
                'Korean',
                'Vietnamese',
                'Japanese',
                'Islamic',
                'Persian',
                'Tibetan',
                'Mongolian',
                'Dai'
              ]}
            />
            <span>Calendar</span>
          </div>
          <div className="calendar-arrows">
            <Arrows
              direction="left"
              size={40}
              titles={[i18n.prevYear[language], i18n.prevMonth[language]]}
              onClick={[this.prevYear, this.prevMonth]}
            />
            <Calendar
              month={currentMonth}
              language={language}
              calendar1={calendar1}
              calendar2={calendar2}
            />
            <Arrows
              direction="right"
              size={40}
              titles={[i18n.nextMonth[language], i18n.nextYear[language]]}
              onClick={[this.nextMonth, this.nextYear]}
            />
          </div>
        </div>
        <Toolbar
          language={language}
          calendar1={calendar1}
          calendar2={calendar2}
          onLanguageChange={this.onLanguageChange}
          onCalendarChange={this.onCalendarChange}
        />
      </div>
    )
  }
}

export default App
