export const festivals = {
  chinese: {
    M1D1: {
      'en-US': 'Chinese New Year',
      'zh-CN': '春节',
      'zh-TW': '春節'
    },
    M1D7: {
      'en-US': 'Human Day',
      'zh-CN': '人日',
      'zh-TW': '人日'
    },
    M1D15: {
      'en-US': 'Lantern Festival',
      'zh-CN': '元宵节',
      'zh-TW': '元宵節'
    },
    M2D1: {
      'en-US': 'Zhonghe Festival',
      'zh-CN': '中和节',
      'zh-TW': '中和節'
    },
    M2D2: {
      'en-US': 'Longtaitou Festival',
      'zh-CN': '龙抬头',
      'zh-TW': '龍抬頭'
    },
    M3D3: {
      'en-US': 'Shangsi Festival',
      'zh-CN': '上巳节',
      'zh-TW': '上巳節'
    },
    M4D8: {
      'en-US': "Buddha's Birthday",
      'zh-CN': '佛诞',
      'zh-TW': '佛誕'
    },
    M5D5: {
      'en-US': 'Dragon Boat Festival',
      'zh-CN': '端午节',
      'zh-TW': '端午節'
    },
    M7D7: {
      'en-US': 'Qixi Festival',
      'zh-CN': '七夕',
      'zh-TW': '七夕'
    },
    M7D15: {
      'en-US': 'Ghost Festival',
      'zh-CN': '中元节',
      'zh-TW': '中元節'
    },
    M8D15: {
      'en-US': 'Mid-Autumn Festival',
      'zh-CN': '中秋节',
      'zh-TW': '中秋節'
    },
    M9D9: {
      'en-US': 'Double Ninth Festival',
      'zh-CN': '重阳节',
      'zh-TW': '重陽節'
    },
    M10D1: {
      'en-US': 'Winter Clothing Festival',
      'zh-CN': '寒衣节',
      'zh-TW': '寒衣節'
    },
    M10D15: {
      'en-US': 'Water Lantern Festival',
      'zh-CN': '下元节',
      'zh-TW': '下元節'
    },
    M12D8: {
      'en-US': 'Laba Festival',
      'zh-CN': '腊八节',
      'zh-TW': '臘八節'
    },
    M12D23: {
      'en-US': 'Kitchen God Festival',
      'zh-CN': '祭灶节',
      'zh-TW': '祭灶節'
    },
    Hanshi: {
      'en-US': 'Cold Food Festival',
      'zh-CN': '寒食节',
      'zh-TW': '寒食節'
    },
    Chuxi: {
      'en-US': "Chinese New Year's Eve",
      'zh-CN': '除夕',
      'zh-TW': '除夕'
    }
  },
  korean: {
    M1D1: {
      'en-US': 'Korean New Year (Seollal)',
      'zh-CN': '朝鲜新年',
      'zh-TW': '朝鮮新年'
    },
    M1D15: {
      'en-US': 'Great Full Moon (Daeboreum)',
      'zh-CN': '大满月',
      'zh-TW': '大滿月'
    },
    M2D1: {
      'en-US': 'Meoseumnal',
      'zh-CN': '长工日',
      'zh-TW': '長工日'
    },
    M3D3: {
      'en-US': 'Double Third Festival (Samjinnal)',
      'zh-CN': '上巳节',
      'zh-TW': '上巳節'
    },
    M4D8: {
      'en-US': "Buddha's Birthday (Chopail)",
      'zh-CN': '浴佛节',
      'zh-TW': '浴佛節'
    },
    M5D5: {
      'en-US': 'Dano',
      'zh-CN': '端午节',
      'zh-TW': '端午節'
    },
    M6D15: {
      'en-US': 'Yudu',
      'zh-CN': '流头节',
      'zh-TW': '流頭節'
    },
    M7D7: {
      'en-US': 'Chilseok',
      'zh-CN': '七夕',
      'zh-TW': '七夕'
    },
    M7D15: {
      'en-US': 'Baekjung',
      'zh-CN': '百中节',
      'zh-TW': '百中節'
    },
    M8D15: {
      'en-US': 'Chuseok',
      'zh-CN': '秋夕',
      'zh-TW': '秋夕'
    },
    M9D9: {
      'en-US': 'Double Ninth Festival (Jungu)',
      'zh-CN': '重九节',
      'zh-TW': '重九節'
    },
    Hansik: {
      'en-US': 'Cold Food Festival (Hansik)',
      'zh-CN': '寒食节',
      'zh-TW': '寒食節'
    },
    SeotdalGeumeum: {
      'en-US': "Korean New Year's Eve (Seotdal Geumeum)",
      'zh-CN': '除夕（腊月晦日）',
      'zh-TW': '除夕（臘月晦日）'
    }
  },
  vietnamese: {
    M1D1: {
      'en-US': 'Vietnamese New Year (Tết)',
      'zh-CN': '越南新年',
      'zh-TW': '越南新年'
    },
    M1D15: {
      'en-US': 'Lantern Festival (Tết Nguyên Tiêu)',
      'zh-CN': '元宵节',
      'zh-TW': '元宵節'
    },
    M3D10: {
      'en-US': "Hùng Kings' Festival (Giỗ tổ Hùng Vương)",
      'zh-CN': '雄王诞',
      'zh-TW': '雄王誕'
    },
    M4D15: {
      'en-US': "Buddha's Birthday (Lễ Phật đản)",
      'zh-CN': '佛诞',
      'zh-TW': '佛誕'
    },
    M5D5: {
      'en-US': 'Mid-Year Festival (Tết Đoan ngọ)',
      'zh-CN': '端午节',
      'zh-TW': '端午節'
    },
    M7D15: {
      'en-US': 'Ghost Festival (Rằm tháng bảy, Vu Lan)',
      'zh-CN': '盂兰盆节',
      'zh-TW': '盂蘭盆節'
    },
    M8D15: {
      'en-US': 'Mid-Autumn Festival (Tết Trung thu)',
      'zh-CN': '中秋节',
      'zh-TW': '中秋節'
    },
    M12D23: {
      'en-US': 'Kitchen God Festival (Ông Táo chầu trời)',
      'zh-CN': '祭灶节',
      'zh-TW': '祭灶節'
    }
  },
  japanese: {
    M1D1: {
      'en-US': 'Japanese New Year (Shōgatsu)',
      'zh-CN': '日本新年',
      'zh-TW': '日本新年'
    },
    M1D7: {
      'en-US': 'Human Day (Jinjitsu)',
      'zh-CN': '人日',
      'zh-TW': '人日'
    },
    M1D15: {
      'en-US': 'Little New Year (Koshōgatsu)',
      'zh-CN': '小正月',
      'zh-TW': '小正月'
    },
    M3D3: {
      'en-US': 'Jōshi',
      'zh-CN': '上巳节',
      'zh-TW': '上巳節'
    },
    HachijuHachiya: {
      'en-US': '88th Night (Hachijū hachiya)',
      'zh-CN': '八十八夜',
      'zh-TW': '八十八夜'
    },
    M5D5: {
      'en-US': 'Tango',
      'zh-CN': '端午节',
      'zh-TW': '端午節'
    },
    M7D7: {
      'en-US': 'Tanabata',
      'zh-CN': '七夕',
      'zh-TW': '七夕'
    },
    M7D15: {
      'en-US': 'Bon Festival (Obon)',
      'zh-CN': '盂兰盆节',
      'zh-TW': '盂蘭盆節'
    },
    M8D15: {
      'en-US': 'Tsukimi',
      'zh-CN': '八月十五夜',
      'zh-TW': '八月十五夜'
    },
    M9D9: {
      'en-US': 'Chōyō',
      'zh-CN': '重阳节',
      'zh-TW': '重陽節'
    },
    M11D15: {
      'en-US': 'Seven-Five-Three Festival (Shichi-Go-San)',
      'zh-CN': '七五三节',
      'zh-TW': '七五三節'
    },
    HatsuUma: {
      'en-US': 'Hatsu-uma',
      'zh-CN': '初午',
      'zh-TW': '初午'
    },
    Setsubun: {
      'en-US': 'Setsubun',
      'zh-CN': '节分',
      'zh-TW': '節分'
    },
    NihyakuToka: {
      'en-US': '210th Day (Nihyaku tōka)',
      'zh-CN': '二百十日',
      'zh-TW': '二百十日'
    },
    NihyakuHatsuka: {
      'en-US': '220th Day (Nihyaku hatsuka)',
      'zh-CN': '二百二十日',
      'zh-TW': '二百二十日'
    },
    Omisoka: {
      'en-US': "Japanese New Year's Eve (Ōmisoka)",
      'zh-CN': '除夕（大晦日）',
      'zh-TW': '除夕（大晦日）'
    }
  },
  tibetan: {
    M1D1: {
      'en-US': 'Losar',
      'zh-CN': '藏历新年',
      'zh-TW': '藏曆新年'
    },
    M1D4: {
      'en-US': 'Beginning of Monlam Prayer Festival',
      'zh-CN': '传召大法会首日',
      'zh-TW': '傳召大法會首日'
    },
    M1D15: {
      'en-US': 'Butter Lamp Festival',
      'zh-CN': '酥油花灯节',
      'zh-TW': '酥油花燈節'
    },
    M4D15: {
      'en-US': 'Saga Dawa Festival',
      'zh-CN': '萨嘎达瓦节',
      'zh-TW': '薩嘎達瓦節'
    },
    M5D15: {
      'en-US': 'World Incense Festival',
      'zh-CN': '林卡节',
      'zh-TW': '林卡節'
    },
    M6D30: {
      'en-US': 'Sho Dun Festival',
      'zh-CN': '雪顿节',
      'zh-TW': '雪頓節'
    },
    M9D22: {
      'en-US': "Buddha's Descending",
      'zh-CN': '降佛节',
      'zh-TW': '降佛節'
    },
    M10D1: {
      'en-US': 'Kongbo New Year',
      'zh-CN': '工布新年',
      'zh-TW': '工布新年'
    },
    M10D15: {
      'en-US': 'Palden Lhamo Festival',
      'zh-CN': '仙女节',
      'zh-TW': '仙女節'
    },
    M10D25: {
      'en-US': 'Tibetan Light Festival',
      'zh-CN': '燃灯节',
      'zh-TW': '燃燈節'
    },
    M12D1: {
      'en-US': 'Shigatse New Year',
      'zh-CN': '日喀则新年',
      'zh-TW': '日喀則新年'
    }
  },
  mongolian: {
    M1D1: {
      'en-US': 'Mongolian New Year (Tsagaan Sar)',
      'zh-CN': '白节',
      'zh-TW': '白節'
    },
    M10D1: {
      'en-US': "Genghis Khan's Birthday",
      'zh-CN': '成吉思汗诞辰日',
      'zh-TW': '成吉思汗誕辰日'
    }
  },
  islamic: {
    M1D1: {
      'en-US': 'Islamic New Year',
      'zh-CN': '伊斯兰新年',
      'zh-TW': '伊斯蘭新年'
    },
    M1D10: {
      'en-US': 'Ashura',
      'zh-CN': '阿舒拉节',
      'zh-TW': '阿舒拉節'
    },
    M2D20: {
      'en-US': "Arba'een",
      'zh-CN': '阿巴因节',
      'zh-TW': '阿巴因節'
    },
    M3D12: {
      'en-US': 'Mawlid (Sunni)',
      'zh-CN': '圣纪节（逊尼派）',
      'zh-TW': '聖紀節（遜尼派）'
    },
    M3D17: {
      'en-US': 'Mawlid (Shia)',
      'zh-CN': '圣纪节（什叶派）',
      'zh-TW': '聖紀節（什葉派）'
    },
    M7D27: {
      'en-US': "Isra and Mi'raj",
      'zh-CN': '登霄夜',
      'zh-TW': '登霄夜'
    },
    M8D15: {
      'en-US': "Mid-Sha'ban",
      'zh-CN': '拜拉特夜',
      'zh-TW': '拜拉特夜'
    },
    M9D1: {
      'en-US': 'First Day of Fasting',
      'zh-CN': '斋月首日',
      'zh-TW': '齋月首日'
    },
    M9D23: {
      'en-US': 'Laylat al-Qadr (Sunni)',
      'zh-CN': '盖德尔夜（什叶派）',
      'zh-TW': '蓋德爾夜（什葉派）'
    },
    M9D27: {
      'en-US': 'Laylat al-Qadr (Sunni)',
      'zh-CN': '盖德尔夜（逊尼派）',
      'zh-TW': '蓋德爾夜（遜尼派）'
    },
    M10D1: {
      'en-US': 'Eid al-Fitr',
      'zh-CN': '开斋节',
      'zh-TW': '開齋節'
    },
    M12D9: {
      'en-US': 'Day of Arafah',
      'zh-CN': '阿拉法日',
      'zh-TW': '阿拉法日'
    },
    M12D10: {
      'en-US': 'Eid al-Adha',
      'zh-CN': '古尔邦节',
      'zh-TW': '古爾邦節'
    }
  },
  persian: {
    M1D1: {
      'en-US': 'Persian New Year (Nowruz)',
      'zh-CN': '波斯新年（纳吾肉孜节）',
      'zh-TW': '波斯新年（納吾肉孜節）'
    },
    M1D13: {
      'en-US': 'Sizdah Be-dar',
      'zh-CN': '自然日',
      'zh-TW': '自然日'
    },
    M4D13: {
      'en-US': 'Tirgan',
      'zh-CN': '雨水节',
      'zh-TW': '雨水節'
    },
    M7D10: {
      'en-US': 'Mehregan',
      'zh-CN': '秋节',
      'zh-TW': '秋節'
    },
    M9D30: {
      'en-US': 'Yaldā Night',
      'zh-CN': '雅尔达之夜',
      'zh-TW': '雅爾達之夜'
    },
    M11D10: {
      'en-US': 'Sadeh',
      'zh-CN': '火节',
      'zh-TW': '火節'
    },
    ChaharshanbeSuri: {
      'en-US': 'Chaharshanbe Suri',
      'zh-CN': '跳火节',
      'zh-TW': '跳火節'
    }
  },
  dai: {
    M9D15: {
      'en-US': 'Door Closing Festival (Hao Wasa)',
      'zh-CN': '关门节',
      'zh-TW': '關門節'
    },
    M12D15: {
      'en-US': 'Door Opening Festival (Ao Wasa)',
      'zh-CN': '开门节',
      'zh-TW': '開門節'
    },
    NewYear: {
      'en-US': 'Dai New Year (Last Day of Songkran)',
      'zh-CN': '傣历新年（泼水节末日）',
      'zh-TW': '傣曆新年（潑水節末日）'
    },
    NewYearEve: {
      'en-US': "Dai New Year's Eve (First Day of Songkran)",
      'zh-CN': '除夕（泼水节首日）',
      'zh-TW': '除夕（潑水節首日）'
    },
    EmptyDay: {
      'en-US': 'Empty Day (During Songkran)',
      'zh-CN': '空日（泼水节期间）',
      'zh-TW': '空日（潑水節期間）'
    }
  }
}
