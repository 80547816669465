export const i18n = {
  selectMonth: {
    'en-US': 'Select month',
    'zh-CN': '请选择月份',
    'zh-TW': '請選擇月份'
  },
  prevYear: {
    'en-US': 'Last year',
    'zh-CN': '上一年',
    'zh-TW': '上一年'
  },
  nextYear: {
    'en-US': 'Next year',
    'zh-CN': '下一年',
    'zh-TW': '下一年'
  },
  prevMonth: {
    'en-US': 'Last month',
    'zh-CN': '上一月',
    'zh-TW': '上一月'
  },
  nextMonth: {
    'en-US': 'Next month',
    'zh-CN': '下一月',
    'zh-TW': '下一月'
  },
  leap: {
    'en-US': 'Leap ',
    'zh-CN': '闰',
    'zh-TW': '閏'
  },
  monthFormat: {
    'en-US': 'MMMM YYYY',
    'zh-CN': 'YYYY年MMM',
    'zh-TW': 'YYYY年MMM'
  },
  dateInfo: {
    'en-US': 'Date information',
    'zh-CN': '日期信息',
    'zh-TW': '日期資訊'
  },
  gregorian: {
    'en-US': '(Proleptic) Gregorian date',
    'zh-CN': '[外推]格里历',
    'zh-TW': '[外推]格里曆'
  },
  julian: {
    'en-US': '(Proleptic) Julian date',
    'zh-CN': '[外推]儒略历',
    'zh-TW': '[外推]儒略曆'
  },
  julianDay: {
    'en-US': 'Julian day',
    'zh-CN': '儒略日',
    'zh-TW': '儒略日'
  },
  reducedJulianDay: {
    'en-US': 'Reduced Julian day',
    'zh-CN': '简化儒略日',
    'zh-TW': '簡化儒略日'
  },
  mainCalNames: {
    gregorian: {
      'en-US': 'Gregorian',
      'zh-CN': '格里历',
      'zh-TW': '格里曆'
    },
    julian: {
      'en-US': 'Julian',
      'zh-CN': '儒略历',
      'zh-TW': '儒略曆'
    }
  },
  auxCalNames: {
    chinese: {
      'en-US': 'Chinese',
      'zh-CN': '中国农历',
      'zh-TW': '中國農曆'
    },
    korean: {
      'en-US': 'Korean',
      'zh-CN': '韩国农历',
      'zh-TW': '韓國農曆'
    },
    vietnamese: {
      'en-US': 'Vietnamese',
      'zh-CN': '越南农历',
      'zh-TW': '越南農曆'
    },
    japanese: {
      'en-US': 'Japanese',
      'zh-CN': '日本旧历',
      'zh-TW': '日本舊曆'
    },
    islamic: {
      'en-US': 'Islamic',
      'zh-CN': '伊斯兰历',
      'zh-TW': '伊斯蘭曆',
      children: {
        'en-US': ['East Islamic', 'West Islamic', 'Fatimid', 'Habash al-Hasib'],
        'zh-CN': ['东伊斯兰', '西伊斯兰', '法蒂玛', '哈巴士'],
        'zh-TW': ['東伊斯蘭', '西伊斯蘭', '法蒂瑪', '哈巴士']
      }
    },
    persian: {
      'en-US': 'Persian',
      'zh-CN': '波斯历',
      'zh-TW': '波斯曆',
      children: {
        'en-US': ['Modern Persian', 'Birashk'],
        'zh-CN': ['通行波斯历', '比拉什克置闰'],
        'zh-TW': ['通行波斯曆', '比拉什克置閏']
      }
    },
    tibetan: {
      'en-US': 'Tibetan',
      'zh-CN': '藏历',
      'zh-TW': '藏曆'
    },
    mongolian: {
      'en-US': 'Mongolian',
      'zh-CN': '蒙古历',
      'zh-TW': '蒙古曆'
    },
    dai: {
      'en-US': 'Dai',
      'zh-CN': '傣历',
      'zh-TW': '傣曆',
      children: {
        'en-US': ['Xitan', 'Suliya'],
        'zh-CN': ['西坦', '苏力牙'],
        'zh-TW': ['西坦', '蘇力牙']
      }
    }
  },
  auxCalInfo: {
    chinese: {
      'en-US': '104 BC – 3000 AD',
      'zh-CN': '公元前104年－公元3000年',
      'zh-TW': '公元前104年－公元3000年'
    },
    korean: {
      'en-US': '1645 AD – 3000 AD',
      'zh-CN': '公元1645年－公元3000年',
      'zh-TW': '公元1645年－公元3000年'
    },
    japanese: {
      'en-US': '1844 AD – 3000 AD',
      'zh-CN': '公元1844年－公元3000年',
      'zh-TW': '公元1844年－公元3000年'
    },
    tibetan: {
      'en-US': '1447 AD – 3000 AD',
      'zh-CN': '公元1447年－公元3000年',
      'zh-TW': '公元1447年－公元3000年'
    },
    mongolian: {
      'en-US': '1786 AD – 3000 AD',
      'zh-CN': '公元1786年－公元3000年',
      'zh-TW': '公元1786年－公元3000年'
    },
    vietnamese: {
      'en-US': '1968 AD – 3000 AD',
      'zh-CN': '公元1968年－公元3000年',
      'zh-TW': '公元1968年－公元3000年'
    },
    persian1: {
      'en-US': '1925 AD – 3000 AD',
      'zh-CN': '公元1925年－公元3000年',
      'zh-TW': '公元1925年－公元3000年'
    },
    persian2: {
      'en-US': '1925 AD – 3000 AD',
      'zh-CN': '公元1925年－公元3000年',
      'zh-TW': '公元1925年－公元3000年'
    },
    dai1: {
      'en-US': '1931 AD – 3000 AD',
      'zh-CN': '公元1931年－公元3000年',
      'zh-TW': '公元1931年－公元3000年'
    },
    dai2: {
      'en-US': '638 AD – 3000 AD',
      'zh-CN': '公元638年－公元3000年',
      'zh-TW': '公元638年－公元3000年'
    }
  },
  auxCalNamesFull: {
    chinese: {
      'en-US': 'Chinese calendar',
      'zh-CN': '中国农历',
      'zh-TW': '中國農曆'
    },
    korean: {
      'en-US': 'Korean calendar',
      'zh-CN': '韩国农历',
      'zh-TW': '韓國農曆'
    },
    japanese: {
      'en-US': 'Japanese calendar',
      'zh-CN': '日本旧历',
      'zh-TW': '日本舊曆'
    },
    tibetan: {
      'en-US': 'Tibetan calendar',
      'zh-CN': '藏历',
      'zh-TW': '藏曆'
    },
    mongolian: {
      'en-US': 'Mongolian calendar',
      'zh-CN': '蒙古历',
      'zh-TW': '蒙古曆'
    },
    vietnamese: {
      'en-US': 'Vietnamese calendar',
      'zh-CN': '越南农历',
      'zh-TW': '越南農曆'
    },
    islamicTabular: {
      'en-US': 'Tabular Islamic calendar',
      'zh-CN': '表式伊斯兰历',
      'zh-TW': '表式伊斯蘭曆'
    },
    persian1: {
      'en-US': 'Modern Persian calendar',
      'zh-CN': '通行波斯历',
      'zh-TW': '通行波斯曆'
    },
    persian2: {
      'en-US': 'Persian Calendar',
      'zh-CN': '波斯历',
      'zh-TW': '波斯曆'
    },
    dai1: {
      'en-US': 'Dai Calendar',
      'zh-CN': '傣历',
      'zh-TW': '傣曆'
    },
    dai2: {
      'en-US': 'Dai Calendar',
      'zh-CN': '傣历',
      'zh-TW': '傣曆'
    }
  },
  auxCalDetails: {
    islamicTabular: {
      'en-US': [
        'The 2nd, 5th, 7th, 10th, 13th, 15th, 18th, 21th, 24th, 26th and 29th year of every 30-year cycle are leap years.',
        'The 2nd, 5th, 7th, 10th, 13th, 16th, 18th, 21th, 24th, 26th and 29th year of every 30-year cycle are leap years.',
        'The 2nd, 5th, 8th, 10th, 13th, 16th, 19th, 21th, 24th, 27th and 29th year of every 30-year cycle are leap years.',
        'The 2nd, 5th, 8th, 11th, 13th, 16th, 19th, 21th, 24th, 27th and 30th year of every 30-year cycle are leap years.'
      ],
      'zh-CN': [
        '每一30年周期的第2、5、7、10、13、15、18、21、24、26、29年为闰年',
        '每一30年周期的第2、5、7、10、13、16、18、21、24、26、29年为闰年',
        '每一30年周期的第2、5、8、10、13、16、19、21、24、27、29年为闰年',
        '每一30年周期的第2、5、8、11、13、16、19、21、24、26、30年为闰年'
      ],
      'zh-TW': [
        '每一30年週期的第2、5、7、10、13、15、18、21、24、26、29年為閏年',
        '每一30年週期的第2、5、7、10、13、16、18、21、24、26、29年為閏年',
        '每一30年週期的第2、5、8、10、13、16、19、21、24、27、29年為閏年',
        '每一30年週期的第2、5、8、11、13、16、19、21、24、26、30年為閏年'
      ]
    },
    persian2: {
      'en-US':
        'Birashk rule: 683 leap years are distributed in every 2820-year cycle.',
      'zh-CN': '比拉什克置闰法：2820年683闰',
      'zh-TW': '比拉什克置閏法：2820年683閏'
    }
  },
  calendar1: {
    'en-US': 'Main calendar',
    'zh-CN': '主历',
    'zh-TW': '主曆'
  },
  calendar2: {
    'en-US': 'Aux. calendar',
    'zh-CN': '辅历',
    'zh-TW': '輔曆'
  },
  ganzhi: {
    'en-US': 'Sexagenary cycle',
    'zh-CN': '干支',
    'zh-TW': '干支'
  },
  zodiac: {
    chinese: {
      'en-US': 'Chinese zodiac',
      'zh-CN': '生肖',
      'zh-TW': '生肖'
    },
    korean: {
      'en-US': 'Korean zodiac',
      'zh-CN': '生肖',
      'zh-TW': '生肖'
    },
    vietnamese: {
      'en-US': 'Vietnamese zodiac',
      'zh-CN': '生肖',
      'zh-TW': '生肖'
    },
    japanese: {
      'en-US': 'Japanese zodiac',
      'zh-CN': '生肖',
      'zh-TW': '生肖'
    },
    dai1: {
      'en-US': 'Dai zodiac',
      'zh-CN': '生肖',
      'zh-TW': '生肖'
    },
    dai2: {
      'en-US': 'Dai zodiac',
      'zh-CN': '生肖',
      'zh-TW': '生肖'
    }
  },
  era: {
    chinese: {
      'en-US': 'Yellow Emperor era',
      'zh-CN': '黄帝纪年',
      'zh-TW': '黃帝紀年'
    },
    korean: {
      'en-US': 'Dangun era',
      'zh-CN': '檀君纪年',
      'zh-TW': '檀君紀年'
    },
    tibetan: {
      'en-US': 'Tibetan royal year',
      'zh-CN': '藏王纪年',
      'zh-TW': '藏王紀年'
    }
  },
  jieqi: {
    'en-US': 'Solar term',
    'zh-CN': '节气',
    'zh-TW': '節氣'
  },
  nines: {
    'en-US': 'Nines of winter',
    'zh-CN': '数九',
    'zh-TW': '數九'
  },
  rokuyou: {
    'en-US': 'Rokuyō',
    'zh-CN': '六曜',
    'zh-TW': '六曜'
  },
  ninesNames: {
    'en-US': [
      'Day 0 of the First Nine',
      'Day 0 of the Second Nine',
      'Day 0 of the Third Nine',
      'Day 0 of the Fourth Nine',
      'Day 0 of the Fifth Nine',
      'Day 0 of the Sixth Nine',
      'Day 0 of the Seventh Nine',
      'Day 0 of the Eighth Nine',
      'Day 0 of the Ninth Nine'
    ],
    'zh-CN': [
      '一九第0天',
      '二九第0天',
      '三九第0天',
      '四九第0天',
      '五九第0天',
      '六九第0天',
      '七九第0天',
      '八九第0天',
      '九九第0天'
    ],
    'zh-TW': [
      '一九第0天',
      '二九第0天',
      '三九第0天',
      '四九第0天',
      '五九第0天',
      '六九第0天',
      '七九第0天',
      '八九第0天',
      '九九第0天'
    ]
  },
  monthsShort: {
    'en-US': [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    'zh-CN': [
      '正月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '冬月',
      '腊月'
    ],
    'zh-TW': [
      '正月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '冬月',
      '腊月'
    ]
  },
  monthsShort2: {
    'en-US': [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    'zh-CN': [
      '正月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ],
    'zh-TW': [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ]
  },
  months: {
    'en-US': [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    'zh-CN': [
      '正月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ],
    'zh-TW': [
      '正月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ]
  },
  monthsKorean: {
    'en-US': [
      'Month Tiger (January)',
      'Month Rabbit (February)',
      'Month Dragon (March)',
      'Month Snake (April)',
      'Month Horse (May)',
      'Month Goat (June)',
      'Month Monkey (July)',
      'Month Rooster (August)',
      'Month Dog (September)',
      'Month Pig (October)',
      'Month Rat (November)',
      'Month Ox (December)'
    ],
    'zh-CN': [
      '正月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ],
    'zh-TW': [
      '正月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ]
  },
  monthsJapanese: {
    'en-US': [
      'January (Mutsuki)',
      'February (Kisaragi)',
      'March (Yayoi)',
      'April (Uzuki)',
      'May (Satsuki)',
      'June (Minazuki)',
      'July (Fumizuki)',
      'August (Hazuki)',
      'September (Nagatsuki)',
      'October (Kannazuki)',
      'November (Shimotsuki)',
      'December (Shiwasu)'
    ],
    'zh-CN': [
      '正月（睦月）',
      '二月（如月）',
      '三月（弥生）',
      '四月（卯月）',
      '五月（皋月）',
      '六月（水无月）',
      '七月（文月）',
      '八月（叶月）',
      '九月（长月）',
      '十月（神无月）',
      '十一月（霜月）',
      '十二月（师走）'
    ],
    'zh-TW': [
      '正月（睦月）',
      '二月（如月）',
      '三月（弥生）',
      '四月（彌月）',
      '五月（皐月）',
      '六月（水無月）',
      '七月（文月）',
      '八月（葉月）',
      '九月（長月）',
      '十月（神無月）',
      '十一月（霜月）',
      '十二月（師走）'
    ]
  },
  monthsTibetan: {
    'en-US': [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    'zh-CN': [
      '正月（神变月）',
      '二月（苦行月）',
      '三月（具香月）',
      '四月（萨嘎月）',
      '五月（作净月）',
      '六月（明净月）',
      '七月（具醉月）',
      '八月（具贤月）',
      '九月（天降月）',
      '十月（持众月）',
      '十一月（庄严月）',
      '十二月（满意月）'
    ],
    'zh-TW': [
      '正月（神變月）',
      '二月（苦行月）',
      '三月（具香月）',
      '四月（薩嘎月）',
      '五月（作凈月）',
      '六月（明凈月）',
      '七月（具醉月）',
      '八月（具賢月）',
      '九月（天降月）',
      '十月（持眾月）',
      '十一月（莊嚴月）',
      '十二月（滿意月）'
    ]
  },
  days: {
    'en-US': [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30'
    ],
    'zh-CN': [
      '初一',
      '初二',
      '初三',
      '初四',
      '初五',
      '初六',
      '初七',
      '初八',
      '初九',
      '初十',
      '十一',
      '十二',
      '十三',
      '十四',
      '十五',
      '十六',
      '十七',
      '十八',
      '十九',
      '二十',
      '廿一',
      '廿二',
      '廿三',
      '廿四',
      '廿五',
      '廿六',
      '廿七',
      '廿八',
      '廿九',
      '三十'
    ],
    'zh-TW': [
      '初一',
      '初二',
      '初三',
      '初四',
      '初五',
      '初六',
      '初七',
      '初八',
      '初九',
      '初十',
      '十一',
      '十二',
      '十三',
      '十四',
      '十五',
      '十六',
      '十七',
      '十八',
      '十九',
      '二十',
      '廿一',
      '廿二',
      '廿三',
      '廿四',
      '廿五',
      '廿六',
      '廿七',
      '廿八',
      '廿九',
      '三十'
    ]
  },
  daiDays: {
    'en-US': [
      'Waxing Day 1',
      'Waxing Day 2',
      'Waxing Day 3',
      'Waxing Day 4',
      'Waxing Day 5',
      'Waxing Day 6',
      'Waxing Day 7',
      'Waxing Day 8',
      'Waxing Day 9',
      'Waxing Day 10',
      'Waxing Day 11',
      'Waxing Day 12',
      'Waxing Day 13',
      'Waxing Day 14',
      'Waxing Day 15',
      'Waning Day 1',
      'Waning Day 2',
      'Waning Day 3',
      'Waning Day 4',
      'Waning Day 5',
      'Waning Day 6',
      'Waning Day 7',
      'Waning Day 8',
      'Waning Day 9',
      'Waning Day 10',
      'Waning Day 11',
      'Waning Day 12',
      'Waning Day 13',
      'Waning Day 14',
      'Waning Day 15'
    ],
    'zh-CN': [
      '月出一日',
      '月出二日',
      '月出三日',
      '月出四日',
      '月出五日',
      '月出六日',
      '月出七日',
      '月出八日',
      '月出九日',
      '月出十日',
      '月出十一日',
      '月出十二日',
      '月出十三日',
      '月出十四日',
      '月出十五日',
      '月下一日',
      '月下二日',
      '月下三日',
      '月下四日',
      '月下五日',
      '月下六日',
      '月下七日',
      '月下八日',
      '月下九日',
      '月下十日',
      '月下十一日',
      '月下十二日',
      '月下十三日',
      '月下十四日',
      '月下十五日'
    ],
    'zh-TW': [
      '月出一日',
      '月出二日',
      '月出三日',
      '月出四日',
      '月出五日',
      '月出六日',
      '月出七日',
      '月出八日',
      '月出九日',
      '月出十日',
      '月出十一日',
      '月出十二日',
      '月出十三日',
      '月出十四日',
      '月出十五日',
      '月下一日',
      '月下二日',
      '月下三日',
      '月下四日',
      '月下五日',
      '月下六日',
      '月下七日',
      '月下八日',
      '月下九日',
      '月下十日',
      '月下十一日',
      '月下十二日',
      '月下十三日',
      '月下十四日',
      '月下十五日'
    ]
  },
  daiDaysShort: {
    'en-US': [
      '1X',
      '2X',
      '3X',
      '4X',
      '5X',
      '6X',
      '7X',
      '8X',
      '9X',
      '10X',
      '11X',
      '12X',
      '13X',
      '14X',
      '15X',
      '1N',
      '2N',
      '3N',
      '4N',
      '5N',
      '6N',
      '7N',
      '8N',
      '9N',
      '10N',
      '11N',
      '12N',
      '13N',
      '14N',
      '15N'
    ],
    'zh-CN': [
      '出一',
      '出二',
      '出三',
      '出四',
      '出五',
      '出六',
      '出七',
      '出八',
      '出九',
      '出十',
      '出十一',
      '出十二',
      '出十三',
      '出十四',
      '出十五',
      '下一',
      '下二',
      '下三',
      '下四',
      '下五',
      '下六',
      '下七',
      '下八',
      '下九',
      '下十',
      '下十一',
      '下十二',
      '下十三',
      '下十四',
      '下十五'
    ],
    'zh-TW': [
      '出一',
      '出二',
      '出三',
      '出四',
      '出五',
      '出六',
      '出七',
      '出八',
      '出九',
      '出十',
      '出十一',
      '出十二',
      '出十三',
      '出十四',
      '出十五',
      '下一',
      '下二',
      '下三',
      '下四',
      '下五',
      '下六',
      '下七',
      '下八',
      '下九',
      '下十',
      '下十一',
      '下十二',
      '下十三',
      '下十四',
      '下十五'
    ]
  },
  gan: {
    'en-US': {
      chinese: [
        'Jia',
        'Yi',
        'Bing',
        'Ding',
        'Wu',
        'Ji',
        'Geng',
        'Xin',
        'Ren',
        'Qui'
      ],
      korean: [
        'Gap',
        'Eul',
        'Byeong',
        'Jeong',
        'Mu',
        'Gi',
        'Gyeong',
        'Shin',
        'Im',
        'Gye'
      ],
      vietnamese: [
        'Giáp',
        'Ất',
        'Bính',
        'Đinh',
        'Mậu',
        'Kỷ',
        'Canh',
        'Tân',
        'Nhâm',
        'Quý'
      ],
      japanese: [
        'Kinoe',
        'Kinoto',
        'Hinoe',
        'Hinoto',
        'Tsuchinoe',
        'Tsuchinoto',
        'Kanoe',
        'Kanoto',
        'Mizunoe',
        'Mizunoto'
      ]
    },
    'zh-CN': ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸'],
    'zh-TW': ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸']
  },
  zhi: {
    'en-US': {
      chinese: [
        'Zi',
        'Chou',
        'Yin',
        'Mao',
        'Chen',
        'Si',
        'Wu',
        'Wei',
        'Shen',
        'You',
        'Xu',
        'Hai'
      ],
      korean: [
        'Ja',
        'Chuk',
        'In',
        'Myo',
        'Jin',
        'Sa',
        'O',
        'Mi',
        'Shin',
        'Yu',
        'Sul',
        'Hae'
      ],
      vietnamese: [
        'Tý',
        'Sửu',
        'Dần',
        'Mão',
        'Thìn',
        'Tỵ',
        'Ngọ',
        'Mùi',
        'Thân',
        'Dậu',
        'Tuất',
        'Hợi'
      ],
      japanese: [
        'Ne',
        'Ushi',
        'Tora',
        'U',
        'Tatsu',
        'Mi',
        'Uma',
        'Hitsuji',
        'Saru',
        'Tori',
        'Inu',
        'I'
      ]
    },
    'zh-CN': [
      '子',
      '丑',
      '寅',
      '卯',
      '辰',
      '巳',
      '午',
      '未',
      '申',
      '酉',
      '戌',
      '亥'
    ],
    'zh-TW': [
      '子',
      '丑',
      '寅',
      '卯',
      '辰',
      '巳',
      '午',
      '未',
      '申',
      '酉',
      '戌',
      '亥'
    ]
  },
  chineseZodiacNames: {
    'en-US': [
      'Rat',
      'Ox',
      'Tiger',
      'Rabbit',
      'Dragon',
      'Snake',
      'Horse',
      'Goat',
      'Monkey',
      'Rooster',
      'Dog',
      'Pig'
    ],
    'zh-CN': [
      '鼠',
      '牛',
      '虎',
      '兔',
      '龙',
      '蛇',
      '马',
      '羊',
      '猴',
      '鸡',
      '狗',
      '猪'
    ],
    'zh-TW': [
      '鼠',
      '牛',
      '虎',
      '兔',
      '龍',
      '蛇',
      '馬',
      '羊',
      '猴',
      '雞',
      '狗',
      '豬'
    ]
  },
  vietnameseZodiacNames: {
    'en-US': [
      'Rat',
      'Water Buffalo',
      'Tiger',
      'Cat',
      'Dragon',
      'Snake',
      'Horse',
      'Goat',
      'Monkey',
      'Rooster',
      'Dog',
      'Pig'
    ],
    'zh-CN': [
      '鼠',
      '水牛',
      '虎',
      '猫',
      '龙',
      '蛇',
      '马',
      '羊',
      '猴',
      '鸡',
      '狗',
      '猪'
    ],
    'zh-TW': [
      '鼠',
      '牛',
      '虎',
      '貓',
      '龍',
      '蛇',
      '馬',
      '羊',
      '猴',
      '雞',
      '狗',
      '豬'
    ]
  },
  japaneseZodiacNames: {
    'en-US': [
      'Rat',
      'Ox',
      'Tiger',
      'Rabbit',
      'Dragon',
      'Snake',
      'Horse',
      'Sheep',
      'Monkey',
      'Rooster',
      'Dog',
      'Wild boar'
    ],
    'zh-CN': [
      '鼠',
      '牛',
      '虎',
      '兔',
      '龙',
      '蛇',
      '马',
      '羊',
      '猴',
      '鸡',
      '狗',
      '猪'
    ],
    'zh-TW': [
      '鼠',
      '牛',
      '虎',
      '兔',
      '龍',
      '蛇',
      '馬',
      '羊',
      '猴',
      '雞',
      '狗',
      '豬'
    ]
  },
  daiZodiacNames: {
    'en-US': [
      'Rat',
      'Ox',
      'Tiger',
      'Rabbit',
      'Big Snake',
      'Small Snake',
      'Horse',
      'Goat',
      'Monkey',
      'Rooster',
      'Dog',
      'Elephant'
    ],
    'zh-CN': [
      '鼠',
      '黄牛',
      '虎',
      '兔',
      '大蛇',
      '小蛇',
      '马',
      '羊',
      '猴',
      '鸡',
      '狗',
      '象'
    ],
    'zh-TW': [
      '鼠',
      '黄牛',
      '虎',
      '兔',
      '大蛇',
      '小蛇',
      '馬',
      '羊',
      '猴',
      '雞',
      '狗',
      '象'
    ]
  },
  tibetanElements: {
    'en-US': ['Wood', 'Fire', 'Earth', 'Iron', 'Water'],
    'zh-CN': ['木', '火', '土', '铁', '水'],
    'zh-TW': ['木', '火', '土', '鐵', '水']
  },
  jieqiNames: {
    'en-US': {
      chinese: [
        'Lichun',
        'Yushui',
        'Jingzhe',
        'Chunfen',
        'Qingming',
        'Guyu',
        'Lixia',
        'Xiaoman',
        'Mangzhong',
        'Xiazhi',
        'Xiaoshu',
        'Dashu',
        'Liqiu',
        'Chushu',
        'Bailu',
        'Qiufen',
        'Hanlu',
        'Shuangjiang',
        'Lidong',
        'Xiaoxue',
        'Daxue',
        'Dongzhi',
        'Xiaohan',
        'Dahan'
      ],
      korean: [
        'Ipchun',
        'Usu',
        'Gyeongchip',
        'Chunbun',
        'Cheongmyeong',
        'Gogu',
        'Ipha',
        'Soman',
        'Mangjong',
        'Haji',
        'Soseo',
        'Daeseo',
        'Ipchu',
        'Cheoseo',
        'Baekno',
        'Chubun',
        'Hanlo',
        'Sanggang',
        'Ipdong',
        'Soseol',
        'Daeseol',
        'Dongji',
        'Sohan',
        'Daehan'
      ],
      vietnamese: [
        'Lập xuân',
        'Vũ thủy',
        'Kinh trập',
        'Xuân phân',
        'Thanh minh',
        'Cốc vũ',
        'Lập hạ',
        'Tiểu mãn',
        'Mang chủng',
        'Hạ chí',
        'Tiểu thử',
        'Đại thử',
        'Lập thu',
        'Xử thử',
        'Bạch lộ',
        'Thu phân',
        'Hàn lộ',
        'Sương giáng',
        'Lập đông',
        'Tiểu tuyết',
        'Đại tuyết',
        'Đông chí',
        'Tiểu hàn',
        'Đại hàn'
      ],
      japanese: [
        'Risshun',
        'Usui',
        'Keichitsu',
        'Shunbun',
        'Seimei',
        'Kokuu',
        'Rikka',
        'Shōman',
        'Bōshu',
        'Geshi',
        'Shōsho',
        'Taisho',
        'Risshū',
        'Shosho',
        'Hakuro',
        'Shūbun',
        'Kanro',
        'Sōkō',
        'Rittō',
        'Shōsetsu',
        'Taisetsu',
        'Tōji',
        'Shōkan',
        'Daikan'
      ]
    },
    'zh-CN': [
      '立春',
      '雨水',
      '惊蛰',
      '春分',
      '清明',
      '谷雨',
      '立夏',
      '小满',
      '芒种',
      '夏至',
      '小暑',
      '大暑',
      '立秋',
      '处暑',
      '白露',
      '秋分',
      '寒露',
      '霜降',
      '立冬',
      '小雪',
      '大雪',
      '冬至',
      '小寒',
      '大寒'
    ],
    'zh-TW': [
      '立春',
      '雨水',
      '驚蛰',
      '春分',
      '清明',
      '穀雨',
      '立夏',
      '小滿',
      '芒種',
      '夏至',
      '小暑',
      '大暑',
      '立秋',
      '處暑',
      '白露',
      '秋分',
      '寒露',
      '霜降',
      '立冬',
      '小雪',
      '大雪',
      '冬至',
      '小寒',
      '大寒'
    ]
  },
  rokuyouNames: {
    'en-US': ['Taian', 'Shakkō', 'Senshō', 'Tomobiki', 'Senbu', 'Butsumetsu'],
    'zh-CN': ['大安', '赤口', '先胜', '友引', '先负', '佛灭'],
    'zh-TW': ['大安', '赤口', '先勝', '友引', '先負', '佛滅']
  },
  islamicMonths: {
    'en-US': [
      'Muh',
      'Saf',
      'Rab1',
      'Rab2',
      'Jum1',
      'Jum2',
      'Raj',
      "Sha'",
      'Ram',
      'Shaw',
      'DhuQ',
      'DhuH'
    ],
    'zh-CN': [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ],
    'zh-TW': [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ]
  },
  islamicMonthsFull: {
    'en-US': [
      'Muḥarram',
      'Ṣafar',
      'Rabīʿ al-Awwal',
      'Rabīʿ ath-Thānī',
      'Jumādá al-Ūlá',
      'Jumādá al-Ākhirah',
      'Rajab',
      'Sha‘bān',
      'Ramaḍān',
      'Shawwāl',
      'Dhū al-Qa‘dah',
      'Dhū al-Ḥijjah'
    ],
    'zh-CN': [
      '穆哈兰姆月',
      '色法尔月',
      '赖比尔·敖外鲁月',
      '赖比尔·阿色尼月',
      '主马达·敖外鲁月',
      '主马达·阿色尼月',
      '赖哲卜月',
      '舍尔邦月',
      '赖买丹月',
      '闪瓦鲁月',
      '都尔喀尔德月',
      '都尔黑哲月'
    ],
    'zh-TW': [
      '穆哈蘭姆月',
      '色法爾月',
      '賴比爾·敖外魯月',
      '賴比爾·阿色尼月',
      '主馬達·敖外魯月',
      '主馬達·阿色尼月',
      '賴哲卜月',
      '舍爾邦月',
      '賴買丹月',
      '閃瓦魯月',
      '都爾喀爾德月',
      '都爾黑哲月'
    ]
  },
  persianMonths: {
    'en-US': [
      'Far',
      'Ord',
      'Kho',
      'Tir',
      'Mor',
      'Sha',
      'Meh',
      'Aba',
      'Aza',
      'Dey',
      'Bah',
      'Esf'
    ],
    'zh-CN': [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ],
    'zh-TW': [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ]
  },
  persianMonthsFull: {
    'en-US': [
      'Farvardin',
      'Ordibehesht',
      'Khordad',
      'Tir',
      'Mordad',
      'Shahrivar',
      'Mehr',
      'Aban',
      'Azar',
      'Dey',
      'Bahman',
      'Esfand'
    ],
    'zh-CN': [
      '法而斡而丁月',
      '阿而的必喜世月',
      '虎而达月',
      '提尔月',
      '木而达月',
      '沙合列斡而月',
      '列黑而月',
      '阿班月',
      '阿咱而月',
      '答亦月',
      '八哈慢月',
      '亦思番达而麻的月'
    ],
    'zh-TW': [
      '法而斡而丁月',
      '阿而的必喜世月',
      '虎而達月',
      '提爾月',
      '木而達月',
      '沙合列斡而月',
      '列黑而月',
      '阿班月',
      '阿咱而月',
      '答亦月',
      '八哈慢月',
      '亦思番達而麻的月'
    ]
  },
  rabjung: {
    'en-US': 'Rabjung Calendrical Cycle',
    'zh-CN': '绕迥',
    'zh-TW': '繞迥'
  },
  jaran: {
    'en-US': 'Jaran Cycle',
    'zh-CN': '胜生周',
    'zh-TW': '勝生周'
  },
  tibetanRepeatedDay: {
    'en-US': 'repeated',
    'zh-CN': '重日',
    'zh-TW': '重日'
  },
  festival: {
    'en-US': 'Festival',
    'zh-CN': '节日',
    'zh-TW': '節日'
  },
  festivalInfo: {
    japanese: {
      'en-US':
        'The Japanese festivals listed here are all based on traditional calendar dates, but note that many traditional festivals has been celebrated on Gregorian dates since 1873.',
      'zh-CN': '此处所列节日均依据旧历而非西历',
      'zh-TW': '此處所列節日均依據舊曆而非西曆'
    }
  }
}
