import React, { Component } from 'react'
import { Tooltip } from 'antd'
import {
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronsRight
} from 'react-icons/fi'

class Arrows extends Component {
  render() {
    const Left =
      this.props.direction === 'left' ? FiChevronsLeft : FiChevronRight
    const Right =
      this.props.direction === 'left' ? FiChevronLeft : FiChevronsRight

    return (
      <>
        <Tooltip title={this.props.titles[0]}>
          <span>
            <Left
              size={this.props.size}
              className={`calendar-arrow ${
                this.props.size < 30 ? 'calendar-arrow-small' : ''
              } init`}
              onClick={this.props.onClick[0]}
            />
          </span>
        </Tooltip>
        <Tooltip title={this.props.titles[1]}>
          <span>
            <Right
              size={this.props.size}
              className={`calendar-arrow ${
                this.props.size < 30 ? 'calendar-arrow-small' : ''
              } init`}
              onClick={this.props.onClick[1]}
            />
          </span>
        </Tooltip>
      </>
    )
  }
}

export default Arrows
