import React, { Component } from 'react'
import { Menu, Dropdown, Tooltip } from 'antd'
import { FaRegCalendar } from 'react-icons/fa'
import { i18n } from '../utils/i18n'

class ToolbarMenuItems extends Component {
  render() {
    const { language, onMenuClick, selectedKeys } = this.props
    const calNames = i18n.auxCalNames
    const calendars = Object.keys(calNames)
    const calInfo = i18n.auxCalInfo
    return (
      <Dropdown
        overlay={
          <Menu onClick={onMenuClick} selectedKeys={selectedKeys}>
            <Menu.Item key="cal1" className="menu-title noselect" disabled>
              {i18n.calendar1[language]}
            </Menu.Item>
            <Menu.Item key="gregorian">
              {i18n.mainCalNames.gregorian[language]}{' '}
            </Menu.Item>
            <Menu.Item key="julian">
              {i18n.mainCalNames.julian[language]}{' '}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="cal2" className="menu-title noselect" disabled>
              {i18n.calendar2[language]}
            </Menu.Item>
            {calendars.map(cal => {
              if (calNames[cal].children == null) {
                return (
                  <Menu.Item key={cal}>
                    <Tooltip
                      title={
                        calInfo && calInfo[cal][language]
                          ? calInfo[cal][language]
                          : ''
                      }
                      placement="left"
                    >
                      {calNames[cal][language]}
                    </Tooltip>
                  </Menu.Item>
                )
              } else {
                return (
                  <Menu.SubMenu key={cal} title={calNames[cal][language]}>
                    {calNames[cal].children[language].map((name, i) => (
                      <Menu.Item key={`${cal}${i + 1}`}>
                        <Tooltip
                          title={
                            calInfo &&
                            calInfo[`${cal}${i + 1}`] &&
                            calInfo[`${cal}${i + 1}`][language]
                              ? calInfo[`${cal}${i + 1}`][language]
                              : ''
                          }
                          placement="left"
                        >
                          {name}
                        </Tooltip>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )
              }
            })}
          </Menu>
        }
        placement="topRight"
      >
        <FaRegCalendar
          size={24}
          className="toolbar-icon"
          color={'var(--dark)'}
        />
      </Dropdown>
    )
  }
}

export default ToolbarMenuItems
