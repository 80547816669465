import React, { Component } from 'react'
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'moment/locale/zh-tw'
import DateDetail from './DateDetail'
import {
  gregorian2julian,
  julian2gregorian,
  getChineseCalendar,
  getIslamicCalendar,
  getTibetanCalendar,
  getPersianCalendar,
  getDaiCalendar
} from '../utils/dates'

class Calendar extends Component {
  state = {
    days: null,
    daysInfo: null,
    showInfo: false,
    currentIdx: 0,
    weekdayNames: moment.weekdaysShort()
  }

  updateDays = () => {
    const { month, language, calendar1, calendar2 } = this.props
    if (month == null) {
      this.setState({ days: null })
      return
    }
    const firstDay =
      calendar1 === 'gregorian'
        ? moment(month)
            .locale('en')
            .startOf('month')
            .startOf('week')
        : julian2gregorian(moment(month).startOf('month'))
            .locale('en')
            .startOf('week')

    this.setState({
      days: Array(42)
        .fill(0)
        .map((_, i) => moment(firstDay).add(i, 'd'))
    })
    if (['chinese', 'korean', 'vietnamese', 'japanese'].includes(calendar2))
      getChineseCalendar(firstDay, language, calendar2).then(dates =>
        this.setState({ daysInfo: dates })
      )
    else if (
      ['islamic1', 'islamic2', 'islamic3', 'islamic4'].includes(calendar2)
    )
      this.setState({
        daysInfo: getIslamicCalendar(firstDay, language, calendar2)
      })
    else if (['tibetan', 'mongolian'].includes(calendar2))
      getTibetanCalendar(firstDay, language, calendar2).then(dates =>
        this.setState({ daysInfo: dates })
      )
    else if (['persian1', 'persian2'].includes(calendar2))
      getPersianCalendar(firstDay, language, calendar2).then(dates =>
        this.setState({ daysInfo: dates })
      )
    else if (['dai1', 'dai2'].includes(calendar2))
      getDaiCalendar(firstDay, language, calendar2).then(dates =>
        this.setState({ daysInfo: dates })
      )
    else this.setState({ daysInfo: null })
  }

  selectDay = idx => {
    this.setState({ showInfo: true, currentIdx: idx })
  }

  hideInfo = () => this.setState({ showInfo: false })

  updateLanguage = () => {
    moment.locale(this.props.language)
    this.setState({ weekdayNames: moment.weekdaysShort() })
    this.updateDays()
  }

  componentDidMount() {
    this.updateLanguage()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.month != null &&
      !moment(prevProps.month).isSame(this.props.month, 'month')
    )
      this.updateDays()
    if (this.props.month == null && prevProps.month !== this.props.month)
      this.setState({ days: null })

    if (this.props.language !== prevProps.language) this.updateLanguage()
    if (this.props.calendar1 !== prevProps.calendar1) this.updateDays()
    if (this.props.calendar2 !== prevProps.calendar2) this.updateDays()
  }

  render() {
    const { month, language, calendar1, calendar2 } = this.props

    return (
      <div className="calendar init">
        <div className="calendar-header">
          {this.state.weekdayNames.map((w, i) => (
            <div className="calendar-weekday noselect" key={i}>
              {w}
            </div>
          ))}
        </div>
        <div className="calendar-main">
          {this.state.days != null
            ? this.state.days.map((d, i) => {
                let dd = calendar1 === 'gregorian' ? d : gregorian2julian(d)
                const day = dd.date() || 29 // invalid Julian dates occur on Feb 29
                if (!dd.isValid())
                  dd = gregorian2julian(moment(d).subtract(1, 'd'))
                const isCurrMonth = dd.isSame(month, 'month')

                return (
                  <div
                    className={`calendar-day noselect ${
                      !isCurrMonth ? 'grey-text' : ''
                    } ${d.isSame(moment(), 'day') ? 'today' : ''}`}
                    onClick={() => this.selectDay(i)}
                    key={i}
                  >
                    <div
                      className={`calendar-day-number ${
                        this.state.daysInfo != null &&
                        this.state.daysInfo[i].highlight
                          ? 'calendar-day-highlight'
                          : ''
                      }`}
                    >
                      {day}
                    </div>
                    <div
                      className={`calendar-day-info ${
                        this.state.daysInfo != null &&
                        this.state.daysInfo[i].main
                          ? 'calendar-day-main'
                          : ''
                      }`}
                    >
                      {this.state.daysInfo != null &&
                        this.state.daysInfo[i].short}
                    </div>
                  </div>
                )
              })
            : Array(42)
                .fill(0)
                .map((_, i) => <div className="calendar-day" key={i} />)}
        </div>
        <DateDetail
          showInfo={this.state.showInfo}
          hideInfo={this.hideInfo}
          language={language}
          calendar2={calendar2}
          date={
            this.state.days ? this.state.days[this.state.currentIdx] : moment()
          }
          info={
            this.state.daysInfo
              ? this.state.daysInfo[this.state.currentIdx]
              : null
          }
        />
      </div>
    )
  }
}

export default Calendar
