import React, { Component } from 'react'
import moment from 'moment'
import { Drawer, Row, Col, Divider, Tooltip } from 'antd'
import { MdInfo } from 'react-icons/md'
import { gregorian2JD, JD2gregorian } from '../utils/dates'
import { i18n } from '../utils/i18n'

const DetailItem = ({ title, content, info }) =>
  content ? (
    <Row>
      <Col span={10} className="detail-item">
        {title}
        {info && (
          <span style={{ paddingLeft: 5 }}>
            <Tooltip title={info}>
              <MdInfo color={'#aaa'} />
            </Tooltip>
          </span>
        )}
      </Col>
      <Col span={10} className="detail-item" style={{ fontWeight: 'bold' }}>
        {content}
      </Col>
    </Row>
  ) : (
    <></>
  )

class DateDetail extends Component {
  render() {
    const { date, language, calendar2 } = this.props
    const julianDay = gregorian2JD(date)
    let julianDate = JD2gregorian(julianDay, null, false)
    let julianDateString = moment(julianDate)
      .locale(language)
      .format('LL')

    // invalid Julian dates only occur on Feb 29
    if (!julianDate.isValid())
      julianDateString = moment(JD2gregorian(julianDay - 1, null, false))
        .locale(language)
        .format('LL')
        .replace(/28(\D)/, '29$1')

    return (
      <div>
        <Drawer
          width={Math.min(500, window.innerWidth * 0.9)}
          title={i18n.dateInfo[language]}
          placement="right"
          visible={this.props.showInfo}
          onClose={this.props.hideInfo}
        >
          <DetailItem
            title={i18n.gregorian[language]}
            content={moment(date)
              .locale(language)
              .format('LL')}
          />
          <DetailItem
            title={i18n.julian[language]}
            content={julianDateString}
          />
          <DetailItem title={i18n.julianDay[language]} content={julianDay} />
          <DetailItem
            title={i18n.reducedJulianDay[language]}
            content={julianDay - 2400000}
          />
          {this.props.info && calendar2 && (
            <>
              <Divider />
              {i18n.auxCalNamesFull[calendar2] && (
                <DetailItem
                  title={i18n.auxCalNamesFull[calendar2][language]}
                  content={this.props.info.fullDate}
                  info={
                    i18n.auxCalDetails[calendar2]
                      ? i18n.auxCalDetails[calendar2][language]
                      : ''
                  }
                />
              )}
              {[
                'chinese',
                'korean',
                'vietnamese',
                'japanese',
                'dai1',
                'dai2'
              ].includes(calendar2) && (
                <>
                  <DetailItem
                    title={i18n.ganzhi[language]}
                    content={this.props.info.ganzhiDate}
                  />
                  <DetailItem
                    title={i18n.zodiac[calendar2][language]}
                    content={this.props.info.zodiac}
                  />
                </>
              )}
              {['chinese', 'korean', 'vietnamese', 'japanese'].includes(
                calendar2
              ) && (
                <DetailItem
                  title={i18n.jieqi[language]}
                  content={this.props.info.jieqi}
                />
              )}
              {calendar2.startsWith('islamic') &&
                this.props.info.fullDates != null && (
                  <>
                    <DetailItem
                      title={i18n.auxCalNamesFull.islamicTabular[language]}
                      content={' '}
                    />
                    {Array(4)
                      .fill(0)
                      .map((_, i) => (
                        <DetailItem
                          key={i}
                          title={
                            '　- ' +
                            i18n.auxCalNames.islamic.children[language][i]
                          }
                          content={this.props.info.fullDates[i]}
                          info={i18n.auxCalDetails.islamicTabular[language][i]}
                        />
                      ))}
                  </>
                )}
              {this.props.info.festival && (
                <DetailItem
                  title={i18n.festival[language]}
                  content={this.props.info.festival}
                  info={
                    i18n.festivalInfo[calendar2]
                      ? i18n.festivalInfo[calendar2][language]
                      : null
                  }
                />
              )}
              {calendar2 === 'chinese' && (
                <DetailItem
                  title={i18n.nines[language]}
                  content={this.props.info.nines}
                />
              )}
              {calendar2 === 'japanese' && (
                <DetailItem
                  title={i18n.rokuyou[language]}
                  content={this.props.info.rokuyou}
                />
              )}
              {i18n.era[calendar2] && (
                <DetailItem
                  title={i18n.era[calendar2][language]}
                  content={this.props.info.traditionalYear}
                />
              )}
            </>
          )}
        </Drawer>
      </div>
    )
  }
}

export default DateDetail
