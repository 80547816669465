import { i18n } from './i18n'
import { year2ZhiIdx, date2ZhiIdx } from './chineseCal'

const monthShift = i => (i + 5) % 12

export const daiMonthNumbers = info =>
  info.LeapMonth === 0
    ? [...Array(12).keys()].map(m => monthShift(m) + 1)
    : [...Array(13).keys()]
        .map(m => (m >= 4 ? m - 1 : m))
        .map(m => monthShift(m) + 1)

export const daiMonthNames = (monthDays, lang) => {
  if (monthDays.length === 12) {
    return monthDays.map((_, i) => [
      i18n.monthsShort2[lang][monthShift(i)],
      i18n.months[lang][monthShift(i)]
    ])
  } else {
    return monthDays.map((_, i) => {
      if (i <= 3) {
        return [
          i18n.monthsShort2[lang][monthShift(i)],
          i18n.months[lang][monthShift(i)]
        ]
      } else if (i === 4) {
        return [
          i18n.leap[lang] + i18n.monthsShort2[lang][monthShift(i - 1)],
          i18n.leap[lang] + i18n.months[lang][monthShift(i - 1)]
        ]
      } else {
        return [
          i18n.monthsShort2[lang][monthShift(i - 1)],
          i18n.months[lang][monthShift(i - 1)]
        ]
      }
    })
  }
}

export const daiMonthDays = info => {
  let m = [29, 30] // June and July
  m = [...m, info.M8 === 1 ? 30 : 29] // August
  m = [...m, 30] // September
  if (info.LeapMonth === 9) m = [...m, 30] // Leap September
  m = [...m, 29, 30, 29, 30, 29, 30, 29, 30] // October to May
  return m
}

export const zodiac = (year, month, date, lang) => {
  const yearZodiac = i18n.daiZodiacNames[lang][year2ZhiIdx(year)]
  const monthZodiac = i18n.daiZodiacNames[lang][month - 1]
  const dayZodiac = i18n.daiZodiacNames[lang][date2ZhiIdx(date)]
  if (lang !== 'en-US') return `${yearZodiac}骨${monthZodiac}血${dayZodiac}皮`
  else {
    return `${yearZodiac} Bone, ${monthZodiac} Blood, ${dayZodiac} Skin`
  }
}
